//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.
//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
//$primary:#6fd188 !default;
/*$primary: #2C5F63 !default;
$secondary: #201001 !default;
$secondary: blue !default;
$success: #9BAA9B !default;
$info: #55ACB4 !default;
$warning: yellow !default;
$danger: red !default;
$light: white !default;
$dark: #201001 !default;*/
// 
// 
// .btn-primary, .navbar-vertical .btn-purchase, .tox .tox-menu__footer .tox-button:last-child, .tox .tox-dialog__footer .tox-button:last-child {
//     --falcon-btn-color: #fff;
//     --falcon-btn-bg: #6fd188;
//     --falcon-btn-border-color: #6fd188;
//     --falcon-btn-hover-color: #fff;
//     --falcon-btn-hover-bg: #6fd188;
//     --falcon-btn-hover-border-color: #6fd188;
//     --falcon-btn-focus-shadow-rgb: 76, 143, 233;
//     --falcon-btn-active-color: #fff;
//     --falcon-btn-active-bg: #6fd188;
//     --falcon-btn-active-border-color: #6fd188;
//     --falcon-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
//     --falcon-btn-disabled-color: #fff;
//     --falcon-btn-disabled-bg: #6fd188;
//     --falcon-btn-disabled-border-color: #6fd188;
// }