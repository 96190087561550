
.navbar-vertical {
    &.navbar-vibrant .navbar-collapse {
        background-image: linear-gradient(45deg, rgba(51,111,121,1) 0%, rgba(111,174,184,0.82) 100%);
        //background: linear-gradient(225deg, rgba(51,111,121,1) 0%, rgba(111,174,184,0.8085828081232493) 100%);

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        backface-visibility: hidden;
    }

    .nav-link {
        text-transform: uppercase;
        font-size: 12px;
    }
}

label,label.form-label {
    text-transform: uppercase;
    font-size: 11px;
}


table.table th {
    font-size: 11px;
    text-transform: uppercase;
    color: black;
}

.table-circle-img{
    width: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    img{
        width: auto;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}


@media screen and (min-width: 1540px){
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1480px;
    }
}

#root.frontend-client{

    .bg-dark {
        background: rgba(32,16,1,1) !important;
    }

    nav.navbar-glass-shadow{
        background: rgba(32,16,1,1) !important;
    }

    nav{
        li{
            a{
                color: white;
            }
        }
    }

    .icons-services{
        .img-fluid{
            height: 90px;
        }
    }

}


.mult-select-tag {
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-color: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    --border-color: rgb(218, 221, 224);
    font-family: Verdana, sans-serif;
}

.mult-select-tag .wrapper {
    width: 100%;
}

.mult-select-tag .body {
    display: flex;
    border: 1px solid var(--border-color);
    background: white;
    min-height: 2.15rem;
    width:100%;
    min-width: 14rem;

}

.mult-select-tag .input-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: 0.1rem;
}

.mult-select-tag .input-body {
    display: flex;
    width: 100%;
}

.mult-select-tag .input {
    flex:1;
    background: transparent;
    border-radius: 0.25rem;
    padding: 0.45rem;
    margin: 10px;
    color: #2d3748;
    outline: 0;
    border: 1px solid var(--border-color);
}

.mult-select-tag .btn-container {
    color: #e2eBf0;
    padding: 0.5rem;
    display: flex;
    border-left: 1px solid var(--border-color);
}

.mult-select-tag button {
    cursor: pointer;
    width: 100%;
    color: #718096;
    outline: 0;
    height: 100%;
    border: none;
    padding: 0;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    text-transform: none;
    margin: 0;
}

.mult-select-tag button:first-child {
    width: 1rem;
    height: 90%;
}


.mult-select-tag .drawer {
    position: absolute;
    background: white;
    max-height: 15rem;
    z-index: 40;
    top: 98%;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
}

.mult-select-tag ul {
    list-style-type: none;
    padding: 0.5rem;
    margin: 0;
}

.mult-select-tag ul li {
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.mult-select-tag ul li:hover {
    background: rgb(243 244 246);
}

.mult-select-tag .item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2c7a7b;
    padding: 0.2rem 0.4rem;
    margin: 0.2rem;
    font-weight: 500;
    border: 1px solid #81e6d9;
    background: #e6fffa;
    border-radius: 9999px;
}

.mult-select-tag .item-label {
    max-width: 100%;
    line-height: 1;
    font-size: 0.75rem;
    font-weight: 400;
    flex: 0 1 auto;
    color: #2c7a7b;
}

.mult-select-tag .item-close-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row-reverse;
}

.mult-select-tag .item-close-svg {
    width: 1rem;
    margin-left: 0.5rem;
    height: 1rem;
    cursor: pointer;
    border-radius: 9999px;
    display: block;
}

.hidden {
    display: none;
}

.mult-select-tag .shadow  {
    //box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mult-select-tag .rounded {
    border-radius: .375rem;
}

//user dashboard
.user-dashboard{
    &.icons-services{
        .img-fluid{
            height: 90px;
        }
    }
}

//Loader
#loader-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 9999;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.loader{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

/* LOADER 1 */

#loader-1:before, #loader-1:after{
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: #3498db;
}

#loader-1:before{
    z-index: 100;
    animation: spin 1s infinite;
}

#loader-1:after{
    border: 10px solid #ccc;
}
@keyframes spin{
    0%{
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100%{
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}